const ATTR_F_NAME = "data-cofi-payable-multi-first-name";
const ATTR_L_NAME = "data-cofi-payable-multi-last-name";
const ATTR_ZIP = "data-cofi-payable-multi-zip";
const ATTR_CC_LAST_FOUR = "input[data-cofi-payable-multi-cc-last-four]"
const ATTR_CC_EXP = "input[data-cofi-payable-multi-cc-exp]"
const ATTR_CC_TYPE = "input[data-cofi-payable-multi-cc-type]"
const ATTR_ERR_CONTENT = "data-cofi-payable-multi-error-content";
const ATTR_ERR_CONTAINER = "data-cofi-payable-multi-error-container";
const ATTR_CONTAINER_CC = "data-cofi-payable-multi-cc"
const ATTR_CONTAINER_CVV = "data-cofi-payable-multi-cvv"
const ATTR_CONTAINER_EXP = "data-cofi-payable-multi-exp"

const ATTR_SUBMIT = "data-cofi-payable-multi-submit";
const ATTR_SAVE_INFO = "data-cofi-payable-multi-save-info";
const ATTR_CANCEL = "data-cofi-payable-multi-cancel";
const ATTR_TOKEN = "input[data-cofi-payable-multi-auth]";
const SEL_FORM = "form[data-cofi-payable-multi]"

const ATTR_CARD1_AMOUNT = "data-cofi-payable-multi-card1-amount";
const ATTR_CARD1_AMOUNT_DISPLAY = "data-cofi-payable-multi-card1-amount-display";
const ATTR_CARD2_AMOUNT = "data-cofi-payable-multi-card2-amount";
const ATTR_CARD2_TOTAL = "data-cofi-payable-multi-card2-amount-total";
const ATTR_CARD_MINIMUM = "data-cofi-payable-multi-card-minimum";
const ATTR_TOTAL = "data-cofi-payable-multi-total";
const CODE_UNKNOWN = -99999;

const MAX_WAIT_FOR_SUBMIT_MS = 60000;

class ControlStateMulti {
  constructor() {
    this.valid = false;
    this.error = "Uninitialized"
    this.code = CODE_UNKNOWN;
  }
  setValid() {
    this.valid = true;
    this.error = "";
    this.code = 0;
    this.extraInfo = "";

  }
  setInvalid(msg, code, extraInfo = "") {
    this.valid = false;
    this.error = msg;
    this.code = code;
    this.extraInfo = extraInfo;
  }
  get statusSet() {
    return this.code !== CODE_UNKNOWN
  }
}

class PayableMulti {
  initialize() {
    this.initialized = false;
    if (this.loadIntervalId) {
      clearInterval(this.loadIntervalId)
    }
    var candidates = $(SEL_FORM);
    var self = this; // For Event Handlers
    if (candidates.length == 0) {
      return; // nothing to do
    }
    else if (candidates.length > 1) {
      console.error('multiple forms found, this is an error! ');
      return;
    }

    //US only for now
    this.formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });

    this.payableMultiForm = candidates[0];
    this.ccState = new ControlStateMulti();
    this.cvvState = new ControlStateMulti();
    this.expState = new ControlStateMulti();

    // find the various controls
    this.inputFirstName = this.findControl(ATTR_F_NAME);
    this.inputLastName = this.findControl(ATTR_L_NAME);
    this.inputZip = this.findControl(ATTR_ZIP);
    this.containerCC = this.findControl(ATTR_CONTAINER_CC);
    this.containerCVV = this.findControl(ATTR_CONTAINER_CVV);
    this.containerExp = this.findControl(ATTR_CONTAINER_EXP);
    this.hiddenCCLastFour = this.findControl(ATTR_CC_LAST_FOUR);
    this.hiddenCCExp = this.findControl(ATTR_CC_EXP);
    this.hiddenCCType = this.findControl(ATTR_CC_TYPE);
    this.btnSubmit = this.findControl(ATTR_SUBMIT);
    this.btnSaveInfo = this.findControl(ATTR_SAVE_INFO);
    this.btnCancel = this.findControl(ATTR_CANCEL);
    this.errorContent = this.findControl(ATTR_ERR_CONTENT);
    this.errorContainer = this.findControl(ATTR_ERR_CONTAINER);
    this.card1Amount = this.findControl(ATTR_CARD1_AMOUNT);
    this.card1AmountDisplay = this.findControl(ATTR_CARD1_AMOUNT_DISPLAY);
    this.card2Amount = this.findControl(ATTR_CARD2_AMOUNT);
    this.card2Total = this.findControl(ATTR_CARD2_TOTAL);
    this.cardMinimum = this.findControl(ATTR_CARD_MINIMUM);
    this.total = this.findControl(ATTR_TOTAL);
    this.authToken = this.getToken();

    this.paymentFormSubmitted = false;
    this.cardAmountValid = true;

    if (this.cardMinimum) this.cardMinimumValue = this.cardMinimum.val() / 100;

    if (this.authToken == null) {
      console.error("Authentication Missing or Invalid")
      return;
    }

    this.setSubmitEventHandler();

    this.loadIntervalId = setInterval(() => {
      if ("undefined" === typeof (bluesnap)) {
        console.warn("Could not locate BlueSnap Javascript, payments will fail!")
        return;
      }
      bluesnap.hostedPaymentFieldsCreate(this.getBlueSnapConfigForMulti());
      clearInterval(this.loadIntervalId);
    }, 500)

    if (this.card1Amount) {
      this.cardAmountValid = false;
      const totalInvoice = parseFloat(this.total.val());
      this.card1Amount.on('keyup', (event) => {
        let isValid = false;
        var value = event.target.value;
        if (value.length === 0) isValid = true;
        for (let i = 0; i < value.length; i = i + 1) {
          if ((value[i] >= '0' && value[i] <= '9') || value[i] === '.' || value[i] === ',' ) isValid = true;
          else {
            isValid = false;
            break;
          }
        }
        if (!isValid) this.setCardAmountError(['Amount must be a numerical value.']);
        else {
          this.hideErrorMessage();
          this.clearFieldError(this.card1Amount);
          if (this.formValid()) this.setButtonState(this.btnSubmit, true);
        }
      });

      this.card1Amount.on('input', (event) => {
        this.card1AmountDisplay[0].parentElement.children[0].style = 'color: #000';
        this.card1AmountDisplay.html(this.getCardContent(this.card1Amount.val().replace(/,/g, '')));
        const remainder = this.total.val() - event.target.value.replace(/,/g, '');
        const card2_amount = (Math.round(remainder * 100) / 100).toFixed(2);
        this.card2Total.val(card2_amount);
        this.card2Amount[0].parentElement.children[0].style = 'color: #000';
        this.card2Amount.html(this.getCardContent(card2_amount));
        if (remainder < this.cardMinimumValue) this.cardAmountValid = false;
      });

      this.card1Amount.on('change', (event) => {
        this.hideErrorMessage();
        this.clearFieldError(this.card1Amount);
        const pattern = /^(?:\d{1,3}(?:,\d{3})*(?:\.\d+0*)?|\d+(?:\.\d+0*)?)$/;
        if (event.target.value < this.cardMinimumValue) {
          this.setCardAmountError([`Please enter an amount higher than $${this.cardMinimumValue.toFixed(2)}.`]);
        }
        else if (event.target.value > totalInvoice) {
          this.setCardAmountError([`Please enter an amount less than or equal to $${totalInvoice.toFixed(2)}.`]);
        }
        else if (!pattern.test(event.target.value)) {
          this.setCardAmountError([`Invalid amount format.`]);
        }
        else {
          const remainder = this.total.val() - event.target.value.replace(/,/g, '');
          const card2_amount = (Math.round(remainder * 100) / 100).toFixed(2);
          this.card2Total.val(card2_amount);
          this.card1AmountDisplay.html(this.getCardContent(this.card1Amount.val().replace(/,/g, '')));
          this.card2Amount.html(this.getCardContent(card2_amount));
          if (remainder < this.cardMinimumValue || isNaN(remainder)) {
            this.setCardAmountError([`Please enter an amount that will allow both payments to be higher than $${this.cardMinimumValue.toFixed(2)}.`]);
          }
          else {
            this.cardAmountValid = true;
            if (this.formValid()) {
              this.resetButtonState();
              if (this.btnSubmit) {
                this.setButtonState(this.btnSubmit, true);
              }
              if (this.btnSaveInfo) {
                this.setButtonState(this.btnSaveInfo, true);
              }
            }
          }
        }
      });
    }
  }

  setCardAmountError(message) {
    this.setFieldError(this.card1Amount);
    this.displayErrors(message);
    this.cardAmountValid = false;
    this.resetButtonState();
  }

  getCardContent(val) {
    let isValid = false;
    for (let i = 0; i < val.length; i = i + 1) {
      if ((val[i] >= '0' && val[i] <= '9') || val[i] === '.') isValid = true;
      else {
        isValid = false;
        break;
      }
    }
    if (!isValid) val = 0;
    return this.formatter.format(val) + "</div>"
  }

  handleSubmit(e, actionType = "", actionPath = "") {
    const self = this;

    if (!self.cardAmountValid) {
      if (!self.card1Amount.val()) {
        this.setFieldError(this.card1Amount);
        this.displayErrors([`Please enter an amount higher than $${this.cardMinimumValue.toFixed(2)}.`]);
      }
      return;
    }

    let canceled = false;
    const hungRequestId = setTimeout(() => {
      if (!self.paymentFormSubmitted) {
        canceled = true;
        self.displayErrors(['This page needs to reload. Please refresh the page and try again.']);
        self.resetButtonState();
      }
    }, MAX_WAIT_FOR_SUBMIT_MS);

    try {
      e.preventDefault();
      this.disableAllButtons();

      if (this.btnSaveInfo && actionPath && this.payableMultiForm) {
        this.payableMultiForm.setAttribute("action", actionPath);
      }

      bluesnap.hostedPaymentFieldsSubmitData((response) => {
        clearTimeout(hungRequestId);
        try {
          if (canceled) {
            console.warn("Request was cancelled");
            return;
          }
          if (null != response.cardData) {
            self.setInputValueIfExists(self.hiddenCCLastFour, response.cardData.last4Digits);
            self.setInputValueIfExists(self.hiddenCCExp, response.cardData.exp);
            self.setInputValueIfExists(self.hiddenCCType, response.cardData.ccType);
            self.paymentFormSubmitted = true;
            window.Rails.fire(this.payableMultiForm, "submit");
          } else {
            var errorArray = response.error;
            errors = [];
            for (i in errorArray) {
              console.log("Received error: tagId= " +
                errorArray[i].tagId + ", errorCode= " +
                errorArray[i].errorCode + ", errorDescription= " +
                errorArray[i].errorDescription);
              errors.push(errorArray[i].errorDescription)
            }
            self.displayErrors(errors)
            self.resetButtonState();
          }
        } catch (e) {
          console.log(`Failure in submit(inner), ${e.message}`);
          // log error
          if (self.paymentFormSubmitted) {
            console.log('submit(inner) - after form submission')
            self.displayErrors(['Payment could not be processed please contact support.']);
          } else {
            self.displayErrors([`We are having trouble contacting the payment processor, please try again. Error Code: ${this.lastStep}`]);
            self.resetButtonState();
          }
        }
      });
    } catch (e) {
      console.log(`Failure in submit(outer), ${e.message}`);
      this.displayErrors([`We are having trouble contacting the payment processor, please try again. Error Code: ${this.lastStep}`]);
      self.resetButtonState();
    }
  }

  findControl(attr) {
    var sel = attr;
    if (attr.indexOf('[') == -1) {
      sel = "*[" + attr + "]";
    }
    let elem = $(sel)
    if (elem.length == 0 || elem.length > 1) {
      return null;
    }
    return elem.first();
  }

  setInputValueIfExists(ctrl, value) {
    if (!ctrl) {
      console.warn(`tried to set ${value} on non existent control`);
      return;
    }
    ctrl.val(value);
  }

  getToken() {
    let elem = $(ATTR_TOKEN);
    if (elem.length > 0) {
      return elem.val()
    }
    return null;
  }

  getBlueSnapConfigForMulti() {
    var self = this;
    // see what type of form control we are using
    let fontSize = $("[data-bluesnap='ccn']").css('font-size') || "16px";
    return {
      token: this.authToken,
      ccnPlaceHolder: "1234 5678 9012 3456",
      cvvPlaceHolder: "CVV",
      expPlaceHolder: "MM / YY",
      // event handlers
      onFieldEventHandler: {
        setupComplete: () => {
          self.initialized = true;
          if (self.btnSubmit) {
            self.updateSubmitState();
          }
          if (self.btnSaveInfo) {
            self.updateSaveInfoState();
          }
        },
        onFocus: (tagId) => {

        },
        onBlur: (tagId) => {

        },
        onError: (tagId, code, description) => {
          self.setError(tagId, code, description);
        },
        onType: (tagId, cardType, cardData) => {
          // console.log(`onType() - ${cardType}`)
        },
        onValid: (tagId) => {
          self.setValid(tagId);
        }
      },
      style: {
        "input": {
          "font-family": 'system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          "font-size": fontSize,
          "font-weight": "400",
          "color": "#495057"
        },
        "::-webkit-input-placeholder": {
          "color": "#6c757d"
        }
      }
    }
  }
  //we always want to be able to cancel now
  setCancelState(enabled) {
    // console.log(this.btnSubmit)
    // if (!this.setButtonState(this.btnCancel, enabled)) {
    //  console.warn("Not tracking Cancel button state as its undefined");
    //}
  }

  setSubmitState(enabled) {
    if (this.btnSubmit) {
      if (!this.setButtonState(this.btnSubmit, enabled)) {
        console.warn("Failed to set Submit button state");
      }
    } else{
      console.warn("Not tracking Submit button state as it's undefined");
    }
  }

  setSaveInfoState(enabled) {
    if (this.btnSaveInfo) {
      if (!this.setButtonState(this.btnSaveInfo, enabled)) {
        console.warn("Failed to set Save Info button state");
      }
    } else {
      console.warn("Not tracking Save Info button state as it's undefined");
    }
  }

  disableAllButtons() {
    if (this.btnSubmit) {
      this.setSubmitState(false);
    }
    if (this.btnSaveInfo) {
      this.setSaveInfoState(false);
    }
    this.setCancelState(false);
  }

  resetButtonState() {
    if (this.btnSubmit) {
      this.updateSubmitState();
    }
    if (this.btnSaveInfo) {
      this.updateSaveInfoState();
    }
    this.setCancelState(true);
    this.setSubmitEventHandler();
  }

  setSubmitEventHandler() {
    if (!this.btnSubmit && !this.btnSaveInfo) return;

    if (this.btnSubmit) {
      this.btnSubmit.off("click").one("click", (e) => {
        this.handleSubmit(e);
      });
    }

    if (this.btnSaveInfo) {
      this.btnSaveInfo.off("click").one("click", (e) => {
        const actionPath = $(e.target).data("action-path");
        this.handleSubmit(e, "saveInfo", actionPath);
      });
    }
  }

  setButtonState(btn, enabled) {
    if (!btn) {
      return false;
    }
    if (enabled) {
      btn.removeClass("disabled");
      btn[0].disabled = '';
    } else {
      btn.addClass("disabled");
      btn[0].disabled = 'true';
    }
    return true;
  }

  updateSubmitState() {
    if (this.formValid()) {
      this.setSubmitState(true)
    } else {
      this.setSubmitState(false)
    }
  }

  updateSaveInfoState() {
    if (this.formValid()) {
      this.setSaveInfoState(true)
    } else {
      this.setSaveInfoState(false)
    }
  }

  displayErrors(errors) {
    console.log(errors)
    if (this.errorContainer || this.errorContent || false) {
      let list = ""
      errors.forEach(elem => {
        list += "<li>" + elem + "</li>"
      })
      let content = "<ul>" + list + "</ul>";
      this.errorContent.html(content);
      this.errorContainer.removeClass("d-none");
    }
  }

  hideErrorMessage() {
    if (this.errorContainer || false) {
      this.errorContainer.addClass("d-none");
    }
    if (this.errorContent || false) {
      this.errorContent.html("")
    }
  }

  setFieldError(elem) {
    if (elem || false) {
      elem.addClass("payment-field-error")
    }
  }

  clearFieldError(elem) {
    if (elem || false) {
      elem.removeClass("payment-field-error");
    }
  }
  updateErrorState() {
    let noErrors = true;
    let errors = [];
    if (!this.ccState.valid && this.ccState.statusSet) {
      noErrors = false;
      errors.push(this.ccState.error);
      this.setFieldError(this.containerCC)
    } else {
      this.clearFieldError(this.containerCC);
    }
    if (!this.cvvState.valid && this.cvvState.statusSet) {
      noErrors = false;
      errors.push(this.cvvState.error);
      this.setFieldError(this.containerCVV)
    } else {
      this.clearFieldError(this.containerCVV);
    }
    if (!this.expState.valid && this.expState.statusSet) {
      noErrors = false;
      errors.push(this.expState.error);
      this.setFieldError(this.containerExp)
    } else {
      this.clearFieldError(this.containerExp);
    }
    console.log(`updateErrorState errors ${noErrors}`);
    if (this.errorContainer || false) {
      if (noErrors) {
        this.hideErrorMessage();
        this.resetButtonState();
      } else {
        this.displayErrors(errors);
      }
    } else {

    }
  }

  formValid() {
    //console.log(`cc ${this.ccState.valid} cvv ${this.cvvState.valid} exp ${this.expState.valid}`);
    return this.initialized && this.ccState.valid && this.cvvState.valid && this.expState.valid && this.cardAmountValid
  }

  getStateFromTagId(tagId) {
    switch (tagId) {
      case "ccn":
        return this.ccState;
      case "cvv":
        return this.cvvState;
      case "exp":
        return this.expState;
      default:
        console.warn(`invalid tag id ${tagId}`);
        return null;
    }
  }

  setValid(tagId) {
    var state = this.getStateFromTagId(tagId);
    if (state !== null) {
      state.setValid();
    }
    this.updateErrorState();
    if (this.btnSubmit) {
      this.updateSubmitState();
    }
    if (this.btnSaveInfo) {
      this.updateSaveInfoState();
    }
  }

  setError(tagId, code, description) {
    let state = this.getStateFromTagId(tagId);
    let msg = "";
    switch (code) {
      case "10":
        if (tagId === "ccn") {
          msg = "Invalid Card Number";
        }
        else if (tagId === "cvv") {
          msg = "Invalid CVV Code";
        }
        else {
          msg = "Invalid Expiration Date";
        }
        break;
      case "22013":
        msg = "Credit Card Type is not Supported";
        break;
      case "14040":
      case "14041":
      case "400":
        msg = "Authorization has expired please refresh and try again";
        break;
      case "14042":
        msg = "An Internal Error of 14042 has occured please contact support";
        break;
      case "403":
      case "404":
      case "500":
        msg = `An Internal Error code ${code} Has Occurred, please try again later`;
        break;
      default:
        msg = `An Unexpected Error code ${code} has occured, please contact support`;
    }
    console.log(`setError() - ${tagId} Msg: ${msg} Code: ${code} Desc ${description}`)
    if (state != null) {
      state.setInvalid(msg, code, description);
    }
    this.updateErrorState();
    if (this.btnSubmit) {
      this.updateSubmitState();
    }
    if (this.btnSaveInfo) {
      this.updateSaveInfoState();
    }
  }
}

const PS_INVOICE_STATUS_ATTR = "*[data-payable-multi-updater-invoice-status]";
const PS_PAYEE_TEMPLATE_ATTR = "*[data-payable-multi-updater-##]"
export class MultiPaymentStatusUpdater {
  constructor(statusUrl, callback = null) {
    this.statusUrl = statusUrl
    this.intervalId = setInterval(() => {
      self = this;
      $.ajax({
        url: this.statusUrl,
        context: this,
        dataType: "json"
      }).done(function (data) {
        self.updateUI(data);
      });
    }, 3000)
    this.callback = callback;
    this.boundUrl = window.location.href;
  }
  updateUI(data) {
    var step = 1;
    var total_steps = data.payees.length
    $(PS_INVOICE_STATUS_ATTR).text(data.status_human)
    data.payees.forEach(elem => {
      $(PS_PAYEE_TEMPLATE_ATTR.replace("##", elem.type)).text(elem.status)
      if (elem.status === "Approved" || elem.status === "Refunded") {
        step++;
      }
    })
    if (this.boundUrl !== window.location.href) {
      console.warn('canceling status updates due to page navigation change, probably due to turbolinks');
      clearInterval(this.intervalId);
      this.callback(false, 'page_change', data);
    } else if (data.status === 'paid' || data.status === 'failed' || data.status === 'pending' || data.status === 'unpaid_failed' || data.status === 'refunded' || data.status === 'partial_refund' || data.status === 'refund_in_process') {
      clearInterval(this.intervalId)
      if (this.callback !== null) {
        this.callback(true, data.status, data)
      }
    } else {
      if (this.callback !== null) {
        this.callback(false, data.status, step, total_steps, data);
      }
    }
  }
}
export default new PayableMulti()
